<template>
  <div id="materialPurchase">
    <el-dialog
      :title="materialPurchaseFormTitle"
      width="1500px"
      :visible.sync="materialPurchaseDialogVisible"
      :close-on-click-modal="false"
      @close="materialPurchaseDialogClose"
    >
      <el-form
        ref="materialPurchaseFormRef"
        :model="materialPurchaseForm"
        :rules="materialPurchaseFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="物料编号" prop="materialNo">
              <el-select
                v-model="materialPurchaseForm.materialNo"
                placeholder="请选择物料"
                clearable
                filterable
                @change="materialPurchaseStockChange"
              >
                <el-option
                  v-for="item in materialPurchaseStockList"
                  :key="item.id"
                  :label="item.materialNo"
                  :value="item.materialNo"
                >
                  <span style="float: left">{{ item.materialNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.productName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="名称" prop="productName">
              <el-input
                v-model="materialPurchaseForm.productName"
                placeholder="请输入名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="materialPurchaseForm.spec"
                placeholder="请输入型号规格"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="materialPurchaseForm.batchNo"
                placeholder="请输入批号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="materialPurchaseForm.quantity"
                placeholder="请输入数量"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="厂家" prop="manufactor">
              <el-input
                v-model="materialPurchaseForm.manufactor"
                placeholder="请输入厂家"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="到货日期" prop="arrivalDate">
              <el-date-picker
                v-model="materialPurchaseForm.arrivalDate"
                placeholder="请选择到货日期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validDate">
              <el-date-picker
                v-model="materialPurchaseForm.validDate"
                placeholder="请选择有效期"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所在位置" prop="location">
              <el-input
                v-model="materialPurchaseForm.location"
                placeholder="请输入所在位置"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="请验单编号" prop="inspectionFormNo">
              <el-input
                v-model="materialPurchaseForm.inspectionFormNo"
                placeholder="请输入请验单编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="进货检验报告单编号" prop="inspectionReportNo">
              <el-input
                v-model="materialPurchaseForm.inspectionReportNo"
                placeholder="请输入进货检验报告单编号"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结余数量" prop="balanceQuantity">
              <el-input
                v-model="materialPurchaseForm.balanceQuantity"
                placeholder="请输入结余数量"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="出入库情况">
          <vxe-toolbar
            v-if="
              materialPurchaseFormTitle === '新增原材料出入库' ||
                materialPurchaseFormTitle === '修改原材料出入库'
            "
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-table-column
              field="outInSituation"
              title="出入库情况"
              width="150"
            >
              <template v-slot="{ row, column }">
                <vxe-radio-group
                  v-model="row[column.property]"
                  @change="outInSituationWatch(row[column.property], row)"
                >
                  <vxe-radio :label="1" content="出库" />
                  <vxe-radio :label="2" content="入库" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="applyDept"
              title="申请部门"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="situationExplain"
              title="情况说明"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              field="outInQuantity"
              title="出入库数量"
              :edit-render="{
                name: '$input',
                immediate: true,
                props: { clearable: true },
                events: { change: updateBalanceQuantity },
              }"
            />
            <vxe-table-column
              field="balanceQuantity"
              title="结余数量"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column field="outInFormNo" title="出入库申请单编号" width="220">
              <template v-slot="{row, column}">
                <el-input v-model="row[column.property]" placeholder="请输入出入库申请单编号">
                  <i slot="suffix" class="el-input__icon el-icon-success" @click="selectOutInFrom(row)" />
                </el-input>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{ name: '$input', props: { clearable: true } }"
            />
            <vxe-table-column
              v-if="
                materialPurchaseFormTitle === '新增原材料出入库' ||
                  materialPurchaseFormTitle === '修改原材料出入库'
              "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="materialPurchaseDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="materialPurchaseFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料编号">
        <el-input
          v-model="searchForm.materialNo"
          placeholder="请输入物料编号"
          clearable
        />
      </el-form-item>
      <el-form-item label="名称">
        <el-input
          v-model="searchForm.productName"
          placeholder="请输入名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CC_MATERIAL_PURCHASE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="materialPurchasePage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialNo" label="物料编号" />
      <el-table-column prop="productName" label="名称" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="balanceQuantity" label="结余数量" />
      <el-table-column prop="manufactor" label="厂家" />
      <el-table-column label="到货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.arrivalDate">{{ scope.row.arrivalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期">
        <template slot-scope="scope">
          <span v-if="scope.row.validDate">{{ scope.row.validDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="location" label="所在位置" />
      <el-table-column prop="inspectionFormNo" label="请验单编号" />
      <el-table-column prop="inspectionReportNo" label="进货检验报告单编号" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PURCHASE_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CC_MATERIAL_PURCHASE_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="materialPurchasePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMaterialPurchase,
  deleteMaterialPurchase,
  updateMaterialPurchase,
  selectMaterialPurchaseInfo,
  selectMaterialPurchaseList
} from '@/api/storage/materialPurchase'
import { selectMaterialPurchaseStockList } from '@/api/storage/materialPurchaseStock'
import { selectOrderInByNo } from '@/api/universal/orderIn'
import { selectOrderOutByNo } from '@/api/storage/orderOut'

export default {
  data () {
    return {
      materialPurchaseDialogVisible: false,
      materialPurchaseFormTitle: '',
      materialPurchaseForm: {
        id: '',
        materialNo: '',
        productName: '',
        spec: '',
        batchNo: '',
        quantity: '',
        manufactor: '',
        arrivalDate: '',
        validDate: '',
        location: '',
        inspectionFormNo: '',
        inspectionReportNo: '',
        balanceQuantity: '',
        type: 1,
        detailJson: ''
      },
      materialPurchaseFormRules: {
        materialNo: [{ required: true, message: '物料编号不能为空', trigger: ['blur', 'change']}]
      },
      materialPurchasePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialNo: '',
        productName: '',
        type: 1,
        isHistory: 0
      },
      detailList: [],
      materialPurchaseStockList: []
    }
  },
  created () {
    selectMaterialPurchaseList(this.searchForm).then((res) => {
      this.materialPurchasePage = res
    })
    selectMaterialPurchaseStockList({ type: 1 }).then((res) => {
      this.materialPurchaseStockList = res.list
    })
  },
  methods: {
    materialPurchaseDialogClose () {
      this.$refs.materialPurchaseFormRef.resetFields()
      this.detailList = []
    },
    materialPurchaseFormSubmit () {
      if (this.materialPurchaseFormTitle === '原材料出入库详情') {
        this.materialPurchaseDialogVisible = false
        return
      }
      this.$refs.materialPurchaseFormRef.validate(async (valid) => {
        if (valid) {
          this.materialPurchaseForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
          if (this.materialPurchaseFormTitle === '新增原材料出入库') {
            await addMaterialPurchase(this.materialPurchaseForm)
          } else if (this.materialPurchaseFormTitle === '修改原材料出入库') {
            await updateMaterialPurchase(this.materialPurchaseForm)
          }
          this.materialPurchasePage = await selectMaterialPurchaseList(this.searchForm)
          this.materialPurchaseDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.materialPurchaseFormTitle = '新增原材料出入库'
      this.materialPurchaseDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMaterialPurchase(row.id)
        if (this.materialPurchasePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.materialPurchasePage = await selectMaterialPurchaseList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.materialPurchaseFormTitle = '修改原材料出入库'
      this.materialPurchaseDialogVisible = true
      this.selectMaterialPurchaseInfoById(row.id)
    },
    handleInfo (index, row) {
      this.materialPurchaseFormTitle = '原材料出入库详情'
      this.materialPurchaseDialogVisible = true
      this.selectMaterialPurchaseInfoById(row.id)
    },
    selectMaterialPurchaseInfoById (id) {
      selectMaterialPurchaseInfo(id).then((res) => {
        this.materialPurchaseForm.id = res.id
        this.materialPurchaseForm.materialNo = res.materialNo
        this.materialPurchaseForm.productName = res.productName
        this.materialPurchaseForm.spec = res.spec
        this.materialPurchaseForm.batchNo = res.batchNo
        this.materialPurchaseForm.quantity = res.quantity
        this.materialPurchaseForm.manufactor = res.manufactor
        this.materialPurchaseForm.arrivalDate = res.arrivalDate
        this.materialPurchaseForm.validDate = res.validDate
        this.materialPurchaseForm.location = res.location
        this.materialPurchaseForm.inspectionFormNo = res.inspectionFormNo
        this.materialPurchaseForm.inspectionReportNo = res.inspectionReportNo
        this.materialPurchaseForm.balanceQuantity = res.balanceQuantity
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMaterialPurchaseList(this.searchForm).then((res) => {
        this.materialPurchasePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMaterialPurchaseList(this.searchForm).then((res) => {
        this.materialPurchasePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMaterialPurchaseList(this.searchForm).then((res) => {
        this.materialPurchasePage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      newRow.outInSituation = 1
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    materialPurchaseStockChange (value) {
      if (value) {
        let materialPurchaseStock = this.materialPurchaseStockList.find(item => item.materialNo === value)
        this.materialPurchaseForm.productName = materialPurchaseStock.productName
        this.materialPurchaseForm.spec = materialPurchaseStock.spec
        this.materialPurchaseForm.manufactor = materialPurchaseStock.manufactor
      } else {
        this.materialPurchaseForm.productName = ''
        this.materialPurchaseForm.spec = ''
        this.materialPurchaseForm.manufactor = ''
      }
    },
    updateBalanceQuantity (value, $event) {
      let currentRow = value.row
      let tempIndex = value.$rowIndex
      let total = Number(value.data[tempIndex ? tempIndex - 1 : 0].balanceQuantity)
      let tempNow = Number($event.value)
      if (currentRow.outInSituation == 1) {
        if (tempNow <= total) {
        } else {
          this.$message.error({ message: '库存已不足', center: true })
          tempNow = total
          currentRow.outInQuantity = total
        }
        currentRow.balanceQuantity = total - tempNow
      } else {
        if (tempIndex == 0) {
          currentRow.balanceQuantity = tempNow
        } else {
          currentRow.balanceQuantity = total + tempNow
        }
      }
      this.materialPurchaseForm.balanceQuantity = currentRow.balanceQuantity
    },
    outInSituationWatch ($event, row) {
      let tempBalanceQuantity = row.balanceQuantity
      if ($event == 1) {
        row.balanceQuantity
          = Number(tempBalanceQuantity) - Number(row.outInQuantity)
      } else {
        row.balanceQuantity
          = Number(tempBalanceQuantity) + Number(row.outInQuantity)
      }
      this.materialPurchaseForm.balanceQuantity = row.balanceQuantity
    },
    selectOutInFrom (row) {
      if (row.outInSituation === 1) {
        selectOrderOutByNo(row.outInFormNo).then(res => {
          for (let item of res.detailList) {
            if (item.materialNo === this.materialPurchaseForm.materialNo) {
              row.applyDept = res.receiveDept
              row.operator = res.editor
              row.situationExplain = item.situationExplain
              row.outInQuantity = item.quantity
              return
            }
          }
        })
      } else {
        selectOrderInByNo(row.outInFormNo).then(res => {
          for (let item of res.detailList) {
            if (item.materialNo === this.materialPurchaseForm.materialNo) {
              row.applyDept = res.applyDept
              row.operator = res.editor
              row.situationExplain = item.situationExplain
              row.outInQuantity = item.quantity
              return
            }
          }
        })
      }
    }
  }
}
</script>

<style>
</style>
